import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";

function Navbar() {

    const [showHamburger, setShowHamburger] = useState()
    return (
        <>
            <nav className={'header small'}>
                <i className="fa-solid fa-bars hamburger" onClick={() => setShowHamburger(!showHamburger)}></i>
                <AnimatePresence>
                    {showHamburger &&
                        <motion.ul
                            style={{display: 'flex', flexDirection: "column", textAlign: 'center', width: '100%'}}
                            initial={{opacity: 0, height: 0}}
                            animate={{opacity: 1, height: 'auto'}}
                            exit={{opacity: 0, height: 0}}
                        >
                            <Link to={'/'}>
                                <li>Home</li>
                            </Link>
                            <Link to={'/shop'}>
                                <li>Shop</li>
                            </Link>
                            <Link to={'/contacts'}>
                                <li>Contacts</li>
                            </Link>
                        </motion.ul>
                    }
                </AnimatePresence>
            </nav>
            <nav className={'header big'}>
                <ul>
                    <Link to={'/'}>
                        <li>Home</li>
                    </Link>
                    <Link to={'/shop'}>
                        <li>Shop</li>
                    </Link>
                    <Link to={'/contacts'}>
                        <li>Contacts</li>
                    </Link>
                </ul>
            </nav>
        </>
    );
}

export default Navbar;