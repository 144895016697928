import React from 'react';
import '../App.css'
import Navbar from "../components/navbar";
import copertina from '../Copertina_con_testo.png'
import pdf from '../Perpetual Rain Preview.pdf'
import {Link} from "react-router-dom";

function Shop(props) {

    const onDownloadButton = () => {
        const link = document.createElement("a");
        link.href = pdf;
        link.download = "Perpetual rain preview.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <>
            <div className="shop"/>
            <Navbar/>
            <div style={{textAlign: "center", alignContent: 'center'}}>
                <div className={'copertinaContainer'}>
                    <img src={copertina} alt="copertina" className={'copertina'}/>
                    <div className={'description'}>
                        <h1>Perpetual Rain</h1>
                        <p>Welcome to the Rainy Nights!
                            It's 2121 and it rains. It always rains, as it has been for decades.
                            Ascension Bay's lights shine in the rainy night sky. A city of lights, a city of dangers.
                            Whether you are a hero or simply a hired gun, Ascension Bay is a city of opportunities, so
                            come get involved in the shadiest conspiracies and dirtiest affairs the city has to
                            offer.</p>
                        <button onClick={onDownloadButton}><i className="fa-solid fa-expand"></i> Free preview</button>
                        <Link to ={'https://www.lulu.com/it/shop/edoardo-helmer/perpetual-rain/ebook/product-95q9eev.html?page=1&pageSize=4'}>
                            <button><i className="fa-solid fa-download"></i> Ebook</button>
                        </Link>
                        <Link to ={'https://www.lulu.com/it/shop/edoardo-helmer/perpetual-rain/hardcover/product-w4zq6p6.html?page=1&pageSize=4'}>
                            <button><i className="fa-solid fa-cart-shopping"></i> Hard cover</button>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Shop;