import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import '../App.css'
import '../gallery.css'
import {Link} from "react-router-dom";
import image67 from "../gallery/image67.jpg";
import image1 from "../gallery/image1.jpg";
import image2 from "../gallery/image2.png";
import image3 from "../gallery/image3.png";
import image4 from "../gallery/image4.jpg";
import image5 from "../gallery/image5.png";
import image6 from "../gallery/image6.png";
import Navbar from "../components/navbar";

function Gallery(props) {
    const [showHamburger, setShowHamburger] = useState()
    return (
        <div>
            <Navbar/>
            <div style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: "center",
                color: 'white'
            }}>
                <h1>contact us</h1>
                <p>we are avaible on this email</p>
                <a className={'email'} href="mailto: bedtime_games@outlook.com"> bedtime_games@outlook.com </a>
                <p>and on the discord server</p>
                <Link to={'https://discord.com/invite/zKwZb4XzGp'}>
                    <i style= {{fontSize: 56}} className="fa-brands fa-discord"></i>
                </Link>
            </div>
        </div>
    );
}

export default Gallery;