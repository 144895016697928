import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import '../App.css'
import '../gallery.css'
import {Link} from "react-router-dom";
import Slider from "react-slick";


function Gallery(props) {
    const [showHamburger, setShowHamburger] = useState()
    const importAll = (requireContext) => {
        return requireContext.keys().map(requireContext);
    };

// Funzione che utilizza require.context per importare le immagini
    const imgs = () => {
        // Importa tutte le immagini dalla cartella gallery
        const images = importAll(require.context('../gallery', false, /\.(png|jpe?g|svg)$/));
        return images;
    };

    const [images, setImages] = useState(imgs())
    const [width, setWidth] = useState(0)

    const carousel = useRef();



    useEffect(() => {
        console.log(carousel)
    }, []);

    return (
        <>
            <nav className={'header small'}>
                <i className="fa-solid fa-bars hamburger" onClick={() => setShowHamburger(!showHamburger)}></i>
                <AnimatePresence>
                    {showHamburger &&
                        <motion.ul
                            style={{display: 'flex', flexDirection: "column", textAlign: 'center', width: '100%'}}
                            initial={{opacity: 0, height: 0}}
                            animate={{opacity: 1, height: 'auto'}}
                            exit={{opacity: 0, height: 0}}
                        >
                            <Link to={'/'}>
                                <li>Home</li>
                            </Link>
                            <Link to={'/gallery'}>
                                <li>Gallery</li>
                            </Link>
                            <Link
                                to={'https://www.lulu.com/it/shop/edoardo-helmer/perpetual-rain/ebook/product-95q9eev.html?page=1&pageSize=4'}>
                                <li>Order now</li>
                            </Link>
                            <Link to={'/contacts'}>
                                <li>Contacts</li>
                            </Link>
                        </motion.ul>
                    }
                </AnimatePresence>
            </nav>
            <nav className={'header big'}>
                <ul>
                    <Link to={'/'}>
                        <li>Home</li>
                    </Link>
                    <Link to={'/gallery'}>
                        <li>Gallery</li>
                    </Link>
                    <Link
                        to={'https://www.lulu.com/it/shop/edoardo-helmer/perpetual-rain/ebook/product-95q9eev.html?page=1&pageSize=4'}>
                        <li>Order now</li>
                    </Link>
                    <Link to={'/contacts'}>
                        <li>Contacts</li>
                    </Link>
                </ul>
            </nav>
            <div style={{display: "flex", justifyContent: 'center'}}>
                <div className={'galleryCarousel'}>
                    <Slider>
                        {images.map((img, idx) =>
                            <img src={img} className={'gallery'}/>
                        )}
                    </Slider>
                </div>
            </div>


            <footer className="footer">
                <div>
                    <p>Concept, development, design and writing by Edoardo Helmer</p>

                    <p>Illustrations by Edoardo Helmer, made with Stable Diffusion using models iComix and
                        LandscapeRealistic</p>

                    <p>Additional illustrations by @isbkhurram (country and city maps) and @pentool_girl (corporate
                        logos)</p>

                    <p>Playtesting: Douglas, Wang, Monkey, Ghoul, Nathaniel, Shieldwall, Stella, Fabrice Black</p>
                </div>
            </footer>
        </>
    )
}

export default Gallery;