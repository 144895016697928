import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Contacts from "./pages/Contacts";
import Shop from "./pages/shop";

function App() {
    
    
  return (
      <>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/shop" element={<Shop />}/>
                  <Route path='/gallery' element={<Gallery />}/>
                  <Route path='/contacts' element={<Contacts />}/>
              </Routes>
          </BrowserRouter>
      </>
  );
}

export default App;
