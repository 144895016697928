import tesseract from '../tesseract.png'
import sectec from '../sectec.png'
import {AnimatePresence, motion, useScroll} from 'framer-motion'
import '../App.css';
import copertina from '../Copertina_con_testo.png'
import Navbar from "../components/navbar";
import {Link} from "react-router-dom";

function Home() {


    return (
        <>
            <Navbar/>
            <div style={{position:"relative"}}>
                <div className={'Title'}>
                    <h1 className={'glitch'} style={{fontSize: '5em', color: '#00ddff'}}><em>Bedtime Games</em></h1>
                    <h2>Independent tabletop role-playing game creator</h2>
                </div>
            </div>
            <div className={'container'}>
                <div style={{height: '100vh',  alignContent: 'center', textAlign: "center"}}>
                    <br/>
                   <div><strong><em style={{fontSize: '2em'}}>Perpetual Rain</em></strong></div>
                <div className={'flexSpaceBetween'}>
                    <Link to={'/shop' }>
                            <motion.img
                                initial={{opacity: 0, x: -200}}
                                whileInView={{opacity: 1, x: 0}}
                                transition={{duration: 1, ease: 'easeInOut'}}

                                src={copertina} className={'image'} alt="tesseract enhanced"/>
                    </Link>
                        <div className={'paragraph'} style={{display: "flex", justifyContent: "center"}}>
                            <motion.p style={{width: '80%', textAlign: 'left', fontFamily: 'nirmala UI'}}
                                      initial={{opacity: 0, x: 200}}
                                      whileInView={{opacity: 1, x: 0}}
                                      transition={{duration: 1, ease: 'easeInOut'}}
                            >
                                <strong>The high-tech, hard-boiled, neon-lit, rain-soaked role-playing game.</strong>
                                <em>
                                    <p>The year is 2121, and it always rains. Always.</p>
                                    <p>The rain forms puddles, and in the puddles, you see the reflections of lights of
                                        a thousand colors, the large neon billboards that cover the towering skyscrapers
                                        of Ascension Bay’s center.</p>
                                    <p>You can’t remember the last time you saw the sun, or the last time you saw the
                                        night, for that matter. All that matters is what you are going to do with the
                                        time that has been given to you.</p>
                                    <p>As you look at the scrapers, you remember that you were never cut for that. For
                                        grinding out your days in the hope of one day sitting at the top of them.</p>
                                    <p>As you give the scrapers your back, you venture into the blocks; grim, dirty,
                                        cold and damp, where at every corner something unexpected awaits. Now that’s the
                                        life, provided you have what it takes to survive.</p>
                                </em>
                                <p><strong>Perpetual Rain</strong> is an OSR style, cyberpunk themed role-playing game
                                    that rests on a philosophy of simplicity and brutality, made for lovers of stories
                                    of crime, betrayal and backstabbing on a background of heavy rain and neon lights.
                                </p>

                            </motion.p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
